/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@keyframes LbQhvp7q {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes aQcPiuzs {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.lHW6NCcZ {
  position: relative;
  height: 100%;
}
.u6oInGaq {
  padding: 50px 100px 0 100px;
}
.__9lOWMO6X {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
._GlHsPpX {
  color: inherit;
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 960px) {
  .u6oInGaq {
    padding: 50px 25px 0 25px;
  }
}

